import React from "react";
import { RenderCellContentProps } from "../../types";
import ellipsize from "ellipsize";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import moment from "moment-timezone";
import {
  openImageLightBoxModalAction,
  openReportVersionDataModalAction,
  openVideoLightBoxModalAction,
} from "store/modals";
import videoThumbnailImg from "assets/images/video-thumbnail.png";
import pdfThumbnailImg from "assets/images/pdf-thumbnail.png";
import audioThumbnailImg from "assets/images/Placeholders/audio-placeholder.png";
import useDateFormatter from "hooks/formatDateWithPattern";
import { openAudioModal } from "store/modals/reducer.actions";

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
}) => {
  const dispatch = useDispatch();
  const { profileSettings } = useSelector(
    (state: RootState) => state.userProfileSettings
  );
  const { formatDate } = useDateFormatter();

  const FILE_TYPES = {
    IMAGE: /\.(jpg|jpeg|png|gif|webp|svg|bmp)$/i,
    AUDIO: /\.(mp3|wav|ogg|m4a)$/i,
    VIDEO: /\.(mp4|avi|mkv|wmv|flv|mov)$/i,
    PDF: /\.pdf$/i,
  };

  const containsGroups = (data: any) => /group#\d+/.test(data);

  const isFileType = (url: string, regex: RegExp) => regex.test(url.trim());

  const handleFile = (url: string) => {
    const fileType = url
      .trim()
      .match(/\.([a-z0-9]+)(?:[\?#]|$)/i)?.[1]
      ?.toLowerCase();

    if (isFileType(url, FILE_TYPES.IMAGE)) {
      dispatch(openImageLightBoxModalAction({ width: 1080, filePath: url }));
    } else if (isFileType(url, FILE_TYPES.PDF)) {
      window.open(url, "_blank");
    } else if (isFileType(url, FILE_TYPES.VIDEO)) {
      dispatch(openVideoLightBoxModalAction({ filePath: url, fileType }));
    } else if (isFileType(url, FILE_TYPES.AUDIO)) {
      window.open(url, "_blank");
    }
  };

  const renderMedia = (
    url: string,
    src: string,
    alt: string,
    customClass = ""
  ) => (
    <img
      className={`inline-block cursor-pointer Img_user_Data ${customClass}`}
      height="100"
      width="100"
      src={src}
      alt={alt}
      onClick={() => handleFile(url)}
    />
  );

  const renderAudio = (url: string) => {
    return (
      <img
        className="inline-block Img_user_Data cursor-pointer !rounded-none"
        height="100"
        width="100"
        src={audioThumbnailImg}
        alt="Audio Thumbnail"
        onClick={() => {
          const data = { transcription: "", url: url };
          dispatch(openAudioModal(data));
        }}
      />
    );
  };

  const renderContent = (
    value: string,
    renderFn: (url: string) => JSX.Element,
    typeCheckFn: (url: string) => boolean
  ) => {
    if (typeof value !== "string") return null;
    const urls =
      value.startsWith('"') && value.endsWith('"')
        ? value
            .slice(1, -1)
            .split(",")
            .map((url) => url.trim())
        : [value.trim()];

    return (
      <div
        className={`flex -space-x-2 w-[200px] py-1 px-2 ${urls.length > 1 ? "gap-2" : ""}`}
      >
        {urls.filter(typeCheckFn).map((url, index) => (
          <div key={index}>{renderFn(url)}</div>
        ))}
      </div>
    );
  };

  const getCellValue = (value: any): string | JSX.Element => {
    const newValue =
      value.startsWith('"') && value.endsWith('"') ? value.slice(1, -1) : value;
    const getAudioIfExist = newValue.replace(/""/g, '"');
    let parseData;

    try {
      parseData = JSON.parse(getAudioIfExist);
    } catch (error) {
      console.error("Error parsing cell value", error);
    }

    if (parseData?.transcription || parseData?.url) {
      // Return JSX element with transcription and audio
      return renderTranscriptionWithAudio(
        parseData?.transcription,
        parseData.url
      );
    } else {
      return containsGroups(newValue)
        ? `${newValue}...`
        : ellipsize(newValue, 50);
    }
  };

  const handleOpenDataModal = (value: string, question: string) => {
    dispatch(openReportVersionDataModalAction({ question, group: value }));
  };

  if (cellValue !== null && columnDef.id === "submissionDateTime") {
    const currentTimeZone =
      profileSettings.timezone ||
      Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formattedDate = moment
      .utc(cellValue)
      .tz(currentTimeZone)
      .format("YYYY-MM-DD HH:mm:ss");

    return (
      <div className="relative flex items-start flex-grow py-1 px-3">
        <p className="text-sm text-left text-textMid whitespace-nowrap">
          {formattedDate}
        </p>
      </div>
    );
  }

  const renderTranscriptionWithAudio = (
    transcriptionText: any,
    audioUrl: any
  ) => {
    const data = { transcription: transcriptionText, url: audioUrl };
    return (
      <div className="relative flex">
        <div
          className="mt-2 cursor-pointer h-[35px] w-[35px]"
          onClick={() => dispatch(openAudioModal(data))}
        >
          <img
            src={audioThumbnailImg}
            alt="Play Audio"
            height="50"
            width="50"
          />
        </div>
      </div>
    );
  };
  if (cellValue !== null && cellValue !== undefined) {
    return isFileType(cellValue, FILE_TYPES.IMAGE) ? (
      renderContent(
        cellValue,
        (url) => renderMedia(url, url, "Image", "rounded-full"),
        FILE_TYPES.IMAGE.test.bind(FILE_TYPES.IMAGE)
      )
    ) : isFileType(cellValue, FILE_TYPES.AUDIO) ? (
      renderContent(
        cellValue,
        renderAudio,
        FILE_TYPES.AUDIO.test.bind(FILE_TYPES.AUDIO)
      )
    ) : isFileType(cellValue, FILE_TYPES.VIDEO) ? (
      renderContent(
        cellValue,
        (url) => renderMedia(url, videoThumbnailImg, "Video"),
        FILE_TYPES.VIDEO.test.bind(FILE_TYPES.VIDEO)
      )
    ) : isFileType(cellValue, FILE_TYPES.PDF) ? (
      renderContent(
        cellValue,
        (url) => renderMedia(url, pdfThumbnailImg, "PDF", "!rounded-none"),
        FILE_TYPES.PDF.test.bind(FILE_TYPES.PDF)
      )
    ) : (
      <div className="relative flex items-start flex-grow px-3 py-1 pb-5">
        <p className="text-sm text-left text-textMid whitespace-nowrap">
          {getCellValue(cellValue)}
          {(containsGroups(getCellValue(cellValue)) ||
            (cellValue.length > 50 &&
              !cellValue.includes("transcription"))) && (
            <button
              className="font-medium transition text-primary hover:underline"
              onClick={() => handleOpenDataModal(cellValue, columnDef.header)}
            >
              Read More
            </button>
          )}
        </p>
      </div>
    );
  }

  return null;
};

import React from "react";
import {
  Header,
  NotificationCard,
  Pagination,
} from "Components/NotificationCenter";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "store";
import { filtersInitialState } from "store/filters/initialState";
import { IfetchNotifications } from "store/notificationCenter/initialState";
import { fetchNotificationCenterAction } from "store/notificationCenter";
import { TailSpin } from "react-loader-spinner";
import NoRecordFound from "view/components/NoRecordFound";
import { notificationCenterAction } from "store/filters";

export const NotificationCenter = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { data, stateIs } = useSelector<RootState, IfetchNotifications>(
    (state) => state.notificationCenter
  );
  const { notificationCenterFilter } = useSelector<
    RootState,
    filtersInitialState
  >((state) => state?.Filters);

  useEffect(() => {
    dispatch(fetchNotificationCenterAction());
  }, [notificationCenterFilter]);
  useEffect(() => {
    return () => {
      dispatch(notificationCenterAction());
    };
  }, []);
  return (
    <div className="flex flex-col justify-start items-start w-full pt-4 pb-20 ">
      <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-full gap-3">
        <Header />
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 rounded-lg flex-shrink-0 w-full mt-8 bg-bgWhite dark:bg-secondaryLight">
          {stateIs === "Idle" && data?.notificationsCenter?.length === 0 ? (
            <NoRecordFound />
          ) : stateIs === "Pending" ? (
            <div className="loader min-h-[45vh] justify-center items-center flex w-full">
              <TailSpin
                height="50"
                width="50"
                color="#005C89"
                ariaLabel="tail-spin-loading"
                radius="2"
                wrapperStyle={{}}
                wrapperClass="tailspin-loader"
                visible={true}
              />
            </div>
          ) : (
            <NotificationCard />
          )}
          <Pagination />
        </div>
      </div>
    </div>
  );
};

export default NotificationCenter;

import { CameraIcon, TrashIcon } from "assets/icons/HeroIcons";
import CloudUploadIcon from "assets/icons/HeroIcons/CloudUploadIcon";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { getFileExtension, identifyFileType } from "utils/IdentifyFileType";
import { Toasts } from "view/components/Toasts";
import videoThumbnailImg from "assets/images/video-thumbnail.png";
import pdfThumbnailImg from "assets/images/pdf-thumbnail.png";
import xlsThumbnailImg from "assets/images/Placeholders/xls-file-placeholder.png";
import xlsxThumbnailImg from "assets/images/Placeholders/xlsx-file-placeholder.png";
import {
  openExifViewerModalAction,
  openImageLightBoxModalAction,
  openVideoLightBoxModalAction,
} from "store/modals";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";
import ellipsize from "ellipsize";

interface Choice {
  value: string;
  imageLink: string;
}

interface Accept {
  [key: string]: string[];
}

interface FileUploadProps {
  choices: Choice[];
  name: string;
  handleUploadFile: (file: File[]) => void;
  editMode?: boolean;
  required?: boolean;
  error?: any;
  touched?: any;
  visibleIf?: boolean;
  enableIf?: boolean;
  setValueIf?: boolean;
  setValueExpression?: boolean;
  requiredIf?: boolean;
  files?: File[] | null;
  label: string;
  acceptedTypes: any;
  choicesByUrl: any;
  panel?: any;
  element?: any;
}

export const FileUpload: React.FC<FileUploadProps> = ({
  choices,
  name,
  handleUploadFile,
  editMode,
  required,
  touched,
  error,
  visibleIf = true,
  enableIf = false,
  setValueIf,
  setValueExpression,
  requiredIf,
  files,
  label,
  acceptedTypes,
  choicesByUrl,
  panel,
  element,
}) => {
  // const [modal, setModal] = useState({
  //   isOpen: false,
  // })

  // FILES CONFIG
  const FILES_LIMIT = 50;
  const MAX_FILE_SIZE_MB = 25;

  const DEFAULT_FILE_TYPES = [
    ".jpg",
    ".jpeg",
    ".mp4",
    ".mp3",
    ".pdf",
    ".wav",
    ".mov",
    ".MOV",
    ".png",
    ".PNG",
    ".webm",
  ];

  const acceptedFileTypes = acceptedTypes || DEFAULT_FILE_TYPES;

  const filesArray = Array.isArray(files) ? files : files ? [files] : [];

  const handleOnDropFiles = useCallback(
    (acceptedFiles: any) => {
      let totalSize = 0;
      const updatedFiles = filesArray ? [...filesArray] : [];
      for (const file of acceptedFiles) {
        const fileExtension = file.path
          .slice(file.path.lastIndexOf("."))
          .toLowerCase();
        // Check if the file's extension is not in the list of accepted extensions
        const isExtensionAccepted = Object.values(acceptedFileTypes).some(
          (extArray: any) => extArray.includes(fileExtension)
        );
        if (!isExtensionAccepted) {
          Toasts.error("File not supported");
          continue;
        }

        const fileSizeMB = file.size / (1024 * 1024);

        if (fileSizeMB > MAX_FILE_SIZE_MB) {
          return Toasts.error(
            `File ${file.name} exceeds the maximum size of ${MAX_FILE_SIZE_MB} MB`
          );
        }

        if (updatedFiles.length + 1 > FILES_LIMIT) {
          return Toasts.error(
            `Total files must be less than or equal to ${FILES_LIMIT}`
          );
        }

        // Add the new file to the updated files array
        updatedFiles.push(file);
      }

      // Update the state with the new files
      handleUploadFile(updatedFiles);
    },
    [files]
  );

  const handleDeleteFile = (index: number) => {
    const updatedFiles = filesArray?.filter((_, i) => i !== index);
    handleUploadFile(updatedFiles ?? []);
  };

  const handleUpdateFile = (newFile: any, index: number) => {
    const fileExtension = newFile.path
      .slice(newFile.path.lastIndexOf("."))
      .toLowerCase();

    // Check if the file's extension is not in the list of accepted extensions
    const isExtensionAccepted = Object.values(acceptedFileTypes).some(
      (extArray: any) => extArray.includes(fileExtension)
    );

    if (!isExtensionAccepted) {
      Toasts.error("File not supported");
      return;
    }

    const updatedFiles = filesArray ? [...filesArray] : [];
    updatedFiles[index] = newFile; // Replace the file at the given index
    handleUploadFile(updatedFiles);
  };

  // Handle file drop
  const onDrop = useCallback((acceptedFiles: any) => {
    // Handle dropped files, like uploading or processing
    const fileSize = acceptedFiles[0].size / 1024 / 1024; // convert bytes to mb
    if (fileSize > MAX_FILE_SIZE_MB) {
      Toasts.error("File size must be less than 10 mb!");
      acceptedFiles.splice(0, acceptedFiles.length); // Clear the accepted files
      return;
    }
  }, []);

  // const handleCloseModal = () => {
  //   setModal(prevState => ({
  //     ...prevState,
  //     isOpen: false,
  //   }));
  // }

  // const handleOpenModal = () => {
  //   setModal(prevState => ({
  //     ...prevState,
  //     isOpen: true,
  //   }));
  // }
  return (
    <div
      className="p-4 rounded-lg bg-bgPrimaryLight"
      id={`${element?.groupQuestionName || element?.name}${panel?.sectionId}`}
    >
      <p
        className={`w-full pb-1 text-md text-left capitalize font-Overpass ${
          editMode
            ? "text-primary dark:text-caption font-medium "
            : "text-primary dark:text-caption font-regular "
        }`}
      >
        {label} {required && <span>*</span>}
      </p>

      {/* <MediaRecorderModal
        modal={modal}
        setFiles={setFiles}
        closeModal={handleCloseModal}
      /> */}

      {/* <button onClick={handleCapture} type="button">Capture Image</button> */}
      <div className={`w-full flex flex-col justify-start items-start gap-1 `}>
        {editMode && (
          <div className="dropzone">
            <Dropzone onDrop={handleOnDropFiles} accept={acceptedFileTypes}>
              {({ getRootProps, getInputProps }) => (
                <div
                  className={`w-full flex flex-col justify-center items-center px-4 py-2 rounded-lg  border border-lineDark border-dashed dark:bg-secondaryLight  dark:border-lineLight`}
                >
                  <section>
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      <div className="w-full">
                        <div className="ml-[150px]">
                          <CloudUploadIcon />
                        </div>
                        <p className="dark:text-textMain">
                          Or drag and drop your files here or click in this area
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              )}
            </Dropzone>
            <p className="my-2 text-sm text-secondaryLight dark:text-caption">
              File uploads are limited to a maximum of 50 files. Supported file
              formats include PDF, JPEG, JPG, MP4, and MP3. For optimal
              performance on all devices, we recommend using files that are 25
              MB or less.
            </p>
          </div>
        )}
        {filesArray && filesArray instanceof Array && filesArray.length > 0 ? (
          <div className="flex flex-wrap justify-start w-full gap-3 mt-1 mb-2">
            {filesArray?.map((file, index) => (
              <FileBox
                editMode={editMode}
                file={file}
                index={index} // Pass the index here
                handleDeleteFile={handleDeleteFile}
                handleUpdateFile={handleUpdateFile}
                acceptedFileTypes={acceptedFileTypes}
                key={index} // It's generally better to use a unique ID if available
              />
            ))}
          </div>
        ) : (
          !editMode && (
            <p className="text-textNoSelection font-normal font-Overpass text-[15px] italic">
              (No Selection)
            </p>
          )
        )}
      </div>
    </div>
  );
};

const FileBox = ({
  editMode,
  file,
  index,
  handleDeleteFile,
  handleUpdateFile,
  acceptedFileTypes,
}: any) => {
  const [thumbnail, setThumbnail] = useState(file?.url);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const fileType =
    file instanceof File
      ? identifyFileType(getFileExtension(file.name))
      : typeof file === "object" && file.url
        ? identifyFileType(file.type || "jpg", file.url)
        : null;

  // function getFileType(filePath: any, type: any) {
  //   if (editMode) {
  //     return;
  //   }

  //   if (!type) type = "jpg";

  //   if (type.match(/(jpg|jpeg|png|gif|bmp|tiff|JPG|PNG|JPEG|GIF|BMP)$/)) {
  //     dispatch(
  //       openExifViewerModalAction({
  //         fileUrl: file.url,
  //         fileId: file.id,
  //         fileType: type,
  //         dataType: "image",
  //       })
  //     );
  //   } else if (type === "pdf" || type === "PDF") {
  //     window.open(filePath, "_blank");
  //   } else if (type.match(/(mp4|avi|mkv|wmv|flv|mov)$/)) {
  //     dispatch(
  //       openExifViewerModalAction({
  //         fileUrl: file.url,
  //         fileId: file.id,
  //         fileType: type,
  //         dataType: "video",
  //       })
  //     );
  //   } else if (type === "mp3") {
  //     window.open(filePath.original, "_blank");
  //   } else if (type.match(/(doc|docx|xls|xlsx|ppt|pptx)$/)) {
  //     // Handle document files
  //   } else if (type.match(/(txt|csv)$/)) {
  //     // Handle text files
  //   } else if (type.match(/(zip|rar|7z)$/)) {
  //     // Handle archive files
  //   } else {
  //     return "unknown";
  //   }
  // }

  function getFileType(filePath: any, type: any) {
    if (editMode) return;

    const allowedExtensions = [
      // Image extensions
      "jpg",
      "jpeg",
      "png",
      "gif",
      "bmp",
      "tiff",
      "JPG",
      "PNG",
      "JPEG",
      "GIF",
      "BMP",
      // Video extensions
      "mp4",
      "avi",
      "mkv",
      "wmv",
      "flv",
      "mov",
      // Audio extension
      "mp3",
      // Document extensions
      "doc",
      "docx",
      "xls",
      "xlsx",
      "ppt",
      "pptx",
      // Text file extensions
      "txt",
      "csv",
      // Archive file extensions
      "zip",
      "rar",
      "7z",
    ];

    // If type is not provided, default to "jpg"
    if (!type) {
      type = "jpg";
    }

    // Normalize type to lowercase for comparison
    const lowerType = type.toLowerCase();

    // Check if the provided type is in our allowedExtensions array
    const isAllowed = allowedExtensions.some(
      (ext) => ext.toLowerCase() === lowerType
    );

    // If not allowed, extract the extension from filePath
    if (!isAllowed) {
      try {
        const urlObj = new URL(filePath);
        const pathname = urlObj.pathname;
        type = pathname.substring(pathname.lastIndexOf(".") + 1);
      } catch (error) {
        // Fallback extraction if URL parsing fails
        type = type;
      }
    }

    // Normalize the type again after potential extraction
    const finalType = type.toLowerCase();

    // // Now, perform the appropriate action based on the final file type.
    // if (finalType.match(/^(jpg|jpeg|png|gif|bmp|tiff)$/)) {
    //   dispatch(
    //     openExifViewerModalAction({
    //       fileUrl: file.url,
    //       fileId: file.id,
    //       fileType: type,
    //       dataType: "image",
    //     })
    //   );
    // } else if (finalType === "pdf") {
    //   window.open(filePath, "_blank");
    // } else if (finalType.match(/^(mp4|avi|mkv|wmv|flv|mov)$/)) {
    //   dispatch(
    //     openExifViewerModalAction({
    //       fileUrl: file.url,
    //       fileId: file.id,
    //       fileType: type,
    //       dataType: "video",
    //     })
    //   );
    // } else if (finalType === "mp3") {
    //   window.open(filePath, "_blank");
    // } else if (finalType.match(/^(doc|docx|xls|xlsx|ppt|pptx)$/)) {
    //   // Handle document files
    //   console.log("Document file detected:", type);
    // } else if (finalType.match(/^(txt|csv)$/)) {
    //   // Handle text files
    //   console.log("Text file detected:", type);
    // } else if (finalType.match(/^(zip|rar|7z)$/)) {
    //   // Handle archive files
    //   console.log("Archive file detected:", type);
    // } else {
    //   return "unknown";
    // }


    if (finalType.match(/(jpg|jpeg|png|gif|bmp|tiff|JPG|PNG|JPEG|GIF|BMP)$/)) {
      dispatch(
        openExifViewerModalAction({
          fileUrl: file.url,
          fileId: file.id,
          fileType: finalType,
          dataType: "image",
        })
      );
    } else if (finalType === "pdf" || finalType === "PDF") {
      window.open(filePath, "_blank");
    } else if (finalType.match(/(mp4|avi|mkv|wmv|flv|mov)$/)) {
      dispatch(
        openExifViewerModalAction({
          fileUrl: file.url,
          fileId: file.id,
          fileType: finalType,
          dataType: "video",
        })
      );
    } else if (finalType === "mp3") {
      window.open(filePath.original, "_blank");
    } else if (finalType.match(/(doc|docx|xls|xlsx|ppt|pptx)$/)) {
      // Handle document files
    } else if (finalType.match(/(txt|csv)$/)) {
      // Handle text files
    } else if (finalType.match(/(zip|rar|7z)$/)) {
      // Handle archive files
    } else {
      return "unknown";
    }
  }

  useEffect(() => {
    let newThumbnail: any;
    switch (fileType) {
      case "video":
        newThumbnail = videoThumbnailImg;
        break;
      case "image":
        newThumbnail =
          file instanceof File
            ? URL.createObjectURL(file)
            : typeof file === "object"
              ? file.url
              : file;
        break;
      case "pdf":
        newThumbnail = pdfThumbnailImg;
        break;
      case "audio":
        newThumbnail = "";
        break;
      case "xls":
        newThumbnail = xlsThumbnailImg;
        break;
      case "xlsx":
        newThumbnail = xlsxThumbnailImg;
        break;
      default:
        newThumbnail = null;
    }
    setThumbnail(newThumbnail);
    // Cleanup function for object URLs
    return () => {
      if (newThumbnail && newThumbnail.startsWith("blob:")) {
        URL.revokeObjectURL(newThumbnail);
      }
    };
  }, [file, editMode]);

  return (
    <div
      className={`flex flex-col justify-start ${fileType === "audio" ? "items-start" : "items-end"} mb-4`}
    >
      <div>
        {editMode && (
          <button
            className="gap-1"
            type="button"
            onClick={() => handleDeleteFile(index)}
          >
            <TrashIcon />
          </button>
        )}
      </div>
      <div className="gap-1 p-1 mb-2 border border-dashed rounded-lg dark:bg-secondaryLight dark:border-lineLight">
        <div className="relative w-full h-full">
          <Dropzone
            onDrop={(acceptedFiles) =>
              handleUpdateFile(acceptedFiles[0], index)
            }
            multiple={false}
            accept={acceptedFileTypes}
          >
            {({ getRootProps, getInputProps }) =>
              editMode && (
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <div className="absolute items-center rounded-full right-1 bg-bgWhite w-7 h-7 dark:bg-secondaryLight">
                    <CameraIcon className="ml-0.5 mt-0.5 justify-start items-center w-6 h-6" />
                  </div>
                </div>
              )
            }
          </Dropzone>
          <div
            className={`${!editMode && "cursor-pointer"}`}
            onClick={() => {
              getFileType(file.url, file.type);
            }}
          >
            {fileType === "audio" ? (
              <audio controls>
                <source
                  src={
                    file instanceof File ? URL.createObjectURL(file) : file.url
                  }
                  type="audio/mpeg"
                />
                Your browser does not support the audio element.
              </audio>
            ) : (
              <img
                src={thumbnail}
                alt=""
                className={`w-[138px] h-[80px] mx-auto ${
                  fileType === "pdf" ||
                  fileType === "xls" ||
                  fileType === "xlsx"
                    ? "object-contain"
                    : "object-cover"
                }`}
              />
            )}
          </div>
          <div className="relative w-full" style={{ wordBreak: "break-word" }}>
            <p className="w-full text-center break-words dark:text-textMain ">
              {editMode ? ellipsize(file.name, 12) : ""}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};